import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

import { HelmetProvider } from 'react-helmet-async';

function RootLayout() {
  const helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Header></Header>
        <main>
          <Outlet />
        </main>
        <Footer></Footer>
      </HelmetProvider>
    </>
  );
}

export default RootLayout;
