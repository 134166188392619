import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// import RootLayout from './pages/RootLayout';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';

import logo from './assets/logo/asalon-logo.png';

import Portfolio from './pages/Porfolio';
import ContactUs from './pages/ContactUs';
import Error404 from './pages/Error404';
import RootLayout from './pages/RootLayout/RootLayout';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      errorElement: <Error404 />,

      children: [
        {
          index: 'true',
          element: <Home />,
        },
        {
          path: '/about-us',
          element: <AboutUs />,
        },

        {
          path: '/portfolio',
          element: <Portfolio />,
        },
        {
          path: '/portfolio/:portfolioName',
          element: <Portfolio />,
        },
        {
          path: '/contact-us',
          element: <ContactUs />,
        },
      ],
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
