import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { Link, useLocation } from 'react-router-dom';

import styles from './Navigation.module.css';

import logo from '../../assets/logo/asalon-logo.png';

export default function Navigation() {
  const { pathname } = useLocation();
  return (
    <Navbar expand="lg" className={` pt-3 ${styles['navbar']}`}>
      <Container fluid className={`${styles['navbar-container']}`}>
        <Navbar.Brand href="/" className={styles['navbrand']}>
          <img src={logo} alt="asalon" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`ms-auto ${styles.nav}`}>
            <Link to="/" className={pathname === '/' ? styles.active : ''}>
              HOME
            </Link>
            <Link
              to="/about-us"
              className={pathname.includes('/about-us') ? styles.active : ''}
            >
              ABOUT US
            </Link>
            <Link
              to="/portfolio"
              className={pathname.includes('/portfolio') ? styles.active : ''}
            >
              PORTFOLIO
            </Link>
            <Link
              to="/contact-us"
              className={pathname.includes('/contact-us') ? styles.active : ''}
            >
              CONTACT US
            </Link>
            <Link to="mailto:matt@asalon.co.th">SALES: matt@asalon.co.th</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
