import React, { Fragment } from 'react';
import Footer from './RootLayout/Footer';
import Header from './RootLayout/Header';
import { Link } from 'react-router-dom';

import styles from './Error404.module.css';

const Error404 = function () {
  return (
    <>
      <Header></Header>
      <main className={styles.main}>
        <div className={`mx-auto my-5 container`}>
          <p>PAGE NOT FOUND</p>
          <Link to={'/'}>Click here to go back to home page</Link>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};

export default Error404;
