import { useEffect, useMemo, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import ImageLinkLayout from './portfolioLinkLayout/image-link-layout';

import { useLocation, useMatch, useParams, Link } from 'react-router-dom';

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';

import styles from './Portfolio.module.css';

import abTwinSands from '../assets/images/portfolio/Ab-Twin-Sands-Master_Bedroom.webp';
import chic from '../assets/images/portfolio/Dining-Chairs.webp';
import marriot24 from '../assets/images/portfolio/marriot-24.webp';
import oceanEmerald from '../assets/images/portfolio/Ocean-Emerald-chair.webp';
import recoverAndBespoke from '../assets/images/portfolio/recoverandbespoke.webp';
import royalSiam from '../assets/images/portfolio/Royal-Siam-View-sofa.webp';
import radissonMaiKhao from '../assets/images/portfolio/Radisson-Mai-Khao-Beach-bedroom.webp';
import avaniRiverside from '../assets/images/portfolio/Avani-Riverside-01.webp';
import amariWatergate from '../assets/images/portfolio/Amari-Watergate-01.webp';
import talHaus from '../assets/images/portfolio/tal-haus.webp';
import theChindAsia from '../assets/images/portfolio/Perdomo-seat-01-the-chind-asia.webp';
import marriotResidence from '../assets/images/portfolio/Marriot-Residence.webp';
import amlDevelopment from '../assets/images/portfolio/Elegance-AML-Development.webp';
import radissonResidenceSukhumvit from '../assets/images/portfolio/Radisson-Residence-Sukhumvit.webp';
import villageLaneWineBar from '../assets/images/portfolio/Village-Lane-Wine-Bar-restaurant.webp';
import theEasternOrientExpressTrain from '../assets/images/portfolio/The-Eastern-Orient-Express-Train.webp';

//image gallery import
//ab twin sands
import abtwinsands1 from '../assets/images/portfolio/USE-THESE/Ab Twin Sands/Master_Bedroom.jpg';
import abtwinsands2 from '../assets/images/portfolio/USE-THESE/Ab Twin Sands/P8080171-871-1-1024x768.jpg';
import abtwinsands3 from '../assets/images/portfolio/USE-THESE/Ab Twin Sands/P8080193-879-1024x686.jpg';
import abtwinsands4 from '../assets/images/portfolio/USE-THESE/Ab Twin Sands/P8080200-886-1-1024x728.jpg';
import abtwinsands5 from '../assets/images/portfolio/USE-THESE/Ab Twin Sands/P8080217-921-1024x743.jpg';
import abtwinsands6 from '../assets/images/portfolio/USE-THESE/Ab Twin Sands/P8080232-941-950-1024x792.jpg';
import abtwinsands7 from '../assets/images/portfolio/USE-THESE/Ab Twin Sands/P8080241-951-1024x730.jpg';
import abtwinsands8 from '../assets/images/portfolio/USE-THESE/Ab Twin Sands/P8080252-957-1024x768.jpg';
import abtwinsands9 from '../assets/images/portfolio/USE-THESE/Ab Twin Sands/Typical-Bathroom.jpg';

//chic
import chic1 from '../assets/images/portfolio/USE-THESE/Chic/Dining Chairs 2.jpg';
import chic2 from '../assets/images/portfolio/USE-THESE/Chic/Dining Chairs.jpg';

//marriot 24
import marriot241 from '../assets/images/portfolio/USE-THESE/Marriot 24/HONV1630 - Copy.JPG';
import marriot242 from '../assets/images/portfolio/USE-THESE/Marriot 24/IZZB6296.JPG';
import marriot243 from '../assets/images/portfolio/USE-THESE/Marriot 24/KGOP6448.JPG';
import marriot244 from '../assets/images/portfolio/USE-THESE/Marriot 24/KLBW7162.JPG';
import marriot245 from '../assets/images/portfolio/USE-THESE/Marriot 24/XASW5283.JPG';

//ocean emerald

import oceanemerald1 from '../assets/images/portfolio/USE-THESE/Ocean Emerald/442889b1-b806-4204-a1a9-e7f088f776f2.jpg';
import oceanemerald2 from '../assets/images/portfolio/USE-THESE/Ocean Emerald/742f4782-7022-4e06-92fb-73665bc0df37.jpg';
import oceanemerald3 from '../assets/images/portfolio/USE-THESE/Ocean Emerald/7c8b7860-099f-4c7d-96d8-aff67f04d712.jpg';
import oceanemerald4 from '../assets/images/portfolio/USE-THESE/Ocean Emerald/8af63751-b792-4a0b-b3f7-5ec0019f2ef9.jpg';
import oceanemerald5 from '../assets/images/portfolio/USE-THESE/Ocean Emerald/f0e030b0-b88c-48b8-b7d9-1767e4766565.jpg';

//recover and bespoke

import recovernbespoke1 from '../assets/images/portfolio/USE-THESE/Recover and Bespoke/AZKT7717.JPG';
import recovernbespoke2 from '../assets/images/portfolio/USE-THESE/Recover and Bespoke/Bison.jpeg';
import recovernbespoke3 from '../assets/images/portfolio/USE-THESE/Recover and Bespoke/IMG_4541.JPG';
import recovernbespoke4 from '../assets/images/portfolio/USE-THESE/Recover and Bespoke/Snake1.jpg';
import recovernbespoke5 from '../assets/images/portfolio/USE-THESE/Recover and Bespoke/VZBZ3853.JPG';

//royal siam
import royalsiam1 from '../assets/images/portfolio/USE-THESE/Royal Siam View/07365991-8767-496a-bf5f-df8bcf5848c1.jpg';
import royalsiam2 from '../assets/images/portfolio/USE-THESE/Royal Siam View/1c9e7c77-ddb9-43e3-8e66-62cc7bd588c4.jpg';
import royalsiam3 from '../assets/images/portfolio/USE-THESE/Royal Siam View/a610c20a-09e2-4db0-8f15-4ff27d604bd6.jpg';
import royalsiam4 from '../assets/images/portfolio/USE-THESE/Royal Siam View/TSK_6456-2.jpg';
import royalsiam5 from '../assets/images/portfolio/USE-THESE/Royal Siam View/TSK_6492-2.jpg';
import royalsiam6 from '../assets/images/portfolio/USE-THESE/Royal Siam View/TSK_6521-2.jpg';
import royalsiam7 from '../assets/images/portfolio/USE-THESE/Royal Siam View/TSK_6523-2.jpg';

//radisson maikhao beach
import radissonmaikhao1 from '../assets/images/portfolio/USE-THESE/Radisson Mai Kaew/20191205_153417.jpg';
import radissonmaikhao2 from '../assets/images/portfolio/USE-THESE/Radisson Mai Kaew/20191205_153747.jpg';
import radissonmaikhao3 from '../assets/images/portfolio/USE-THESE/Radisson Mai Kaew/DSCF7447-Edit.jpg';
import radissonmaikhao4 from '../assets/images/portfolio/USE-THESE/Radisson Mai Kaew/DSCF7492-Edit.jpg';
import radissonmaikhao5 from '../assets/images/portfolio/USE-THESE/Radisson Mai Kaew/IMG5590.JPG';
import radissonmaikhao6 from '../assets/images/portfolio/USE-THESE/Radisson Mai Kaew/pic.jpg';

//avani riverside
import avaniriverside1 from '../assets/images/portfolio/USE-THESE/avani-riverside/Avani-Riverside-01.jpg';
import avaniriverside2 from '../assets/images/portfolio/USE-THESE/avani-riverside/Avani-Riverside-02.webp';
import avaniriverside3 from '../assets/images/portfolio/USE-THESE/avani-riverside/Avani-Riverside-03.webp';

//amari watergate
import amariwatergate1 from '../assets/images/portfolio/USE-THESE/amari-watergate/Amari-Watergate-01.webp';
import amariwatergate2 from '../assets/images/portfolio/USE-THESE/amari-watergate/Amari-Watergate-02.webp';
import amariwatergate3 from '../assets/images/portfolio/USE-THESE/amari-watergate/Amari-Watergate-03.webp';

//tal haus
import talhaus1 from '../assets/images/portfolio/USE-THESE/Tal Haus/EGCT8323.JPG';
import talhaus2 from '../assets/images/portfolio/USE-THESE/Tal Haus/GAEX2858.JPG';
import talhaus3 from '../assets/images/portfolio/USE-THESE/Tal Haus/JFSA0075.JPG';
import talhaus4 from '../assets/images/portfolio/USE-THESE/Tal Haus/KVVM8981.JPG';
import talhaus5 from '../assets/images/portfolio/USE-THESE/Tal Haus/LUYD4927.JPG';
import talhaus6 from '../assets/images/portfolio/USE-THESE/Tal Haus/RRIV6058.JPG';
import talhaus7 from '../assets/images/portfolio/USE-THESE/Tal Haus/TAEO9926.JPG';
import talhaus8 from '../assets/images/portfolio/USE-THESE/Tal Haus/TOYR8421.JPG';
import talhaus9 from '../assets/images/portfolio/USE-THESE/Tal Haus/UFRG3584.JPG';
import talhaus10 from '../assets/images/portfolio/USE-THESE/Tal Haus/VRKR2605.JPG';
import talhaus11 from '../assets/images/portfolio/USE-THESE/Tal Haus/XCVG9496.JPG';
import talhaus12 from '../assets/images/portfolio/USE-THESE/Tal Haus/XWEU3067.JPG';

//the chind asia
import thechindasia1 from '../assets/images/portfolio/USE-THESE/the-chind-asia/Perdomo-seat-01.webp';
import thechindasia2 from '../assets/images/portfolio/USE-THESE/the-chind-asia/Perdomo-seat-02.webp';
import thechindasia3 from '../assets/images/portfolio/USE-THESE/the-chind-asia/Perdomo-seat-03.webp';

//marriot residence
import marriotresidence1 from '../assets/images/portfolio/USE-THESE/marriot-residence/Marriott-Residence-01.webp';
import marriotresidence2 from '../assets/images/portfolio/USE-THESE/marriot-residence/Marriott-Residence-02.webp';
import marriotresidence3 from '../assets/images/portfolio/USE-THESE/marriot-residence/Marriott-Residence-03.webp';

//aml development
import AML1 from '../assets/images/portfolio/USE-THESE/AML-development/Elegance-01.webp';
import AML2 from '../assets/images/portfolio/USE-THESE/AML-development/Elegance-02.webp';
import AML3 from '../assets/images/portfolio/USE-THESE/AML-development/Elegance-03.webp';

//radisson residence sukhumvit
import radissonresidencesukhumvit1 from '../assets/images/portfolio/USE-THESE/radisson-residence-sukhumvit/Radisson-Residence-Sukhumvit-01.webp';
import radissonresidencesukhumvit2 from '../assets/images/portfolio/USE-THESE/radisson-residence-sukhumvit/Radisson-Residence-Sukhumvit-02.webp';
import radissonresidencesukhumvit3 from '../assets/images/portfolio/USE-THESE/radisson-residence-sukhumvit/Radisson-Residence-Sukhumvit-03.webp';

//village lane wine bar
import villagelanewinebar1 from '../assets/images/portfolio/USE-THESE/village-wine-bar/Village-Lane-Wine-Bar-01.webp';
//orient express train
import orientexpress1 from '../assets/images/portfolio/USE-THESE/orient-express/The-Eastern-Orient-Express-Train-01.webp';
import orientexpress2 from '../assets/images/portfolio/USE-THESE/orient-express/The-Eastern-Orient-Express-Train-02.webp';
import orientexpress3 from '../assets/images/portfolio/USE-THESE/orient-express/The-Eastern-Orient-Express-Train-03.webp';

const portfolioData = [
  {
    href: '/portfolio/ab-twin-sands',
    imgSrc: abTwinSands,
    alt: 'ab twin sands',
    title: 'ab twin sands',
    description: '',
    imgGallery: [
      abtwinsands1,
      abtwinsands2,
      abtwinsands3,
      abtwinsands4,
      abtwinsands5,
      abtwinsands6,
      abtwinsands7,
      abtwinsands8,
      abtwinsands9,
    ],
  },
  {
    href: '/portfolio/chic',
    imgSrc: chic,
    alt: 'Chic restaurant',
    title: 'Chic',
    description: '',
    imgGallery: [chic1, chic2],
  },
  {
    href: '/portfolio/marriot-24',
    imgSrc: marriot24,
    alt: 'Marriot 24',
    title: 'Marriot 24',
    description: '',
    imgGallery: [marriot241, marriot242, marriot243, marriot244, marriot245],
  },
  {
    href: '/portfolio/ocean-emerald',
    imgSrc: oceanEmerald,
    alt: 'Ocean Emerald',
    title: 'Ocean Emerald',
    description: '',
    imgGallery: [
      oceanemerald1,
      oceanemerald2,
      oceanemerald3,
      oceanemerald4,
      oceanemerald5,
    ],
  },
  {
    href: '/portfolio/recover-and-bespoke',
    imgSrc: recoverAndBespoke,
    alt: 'Recover and Bespoke',
    title: 'Recover and Bespoke',
    description: '',
    imgGallery: [
      recovernbespoke1,
      recovernbespoke2,
      recovernbespoke3,
      recovernbespoke4,
      recovernbespoke5,
    ],
  },
  {
    href: '/portfolio/royal-siam',
    imgSrc: royalSiam,
    alt: 'Royal Siam View',
    title: 'Royal Siam View',
    description: '',
    imgGallery: [
      royalsiam1,
      royalsiam2,
      royalsiam3,
      royalsiam4,
      royalsiam5,
      royalsiam6,
      royalsiam7,
    ],
  },
  {
    href: '/portfolio/radisson-mai-khao-beach',
    imgSrc: radissonMaiKhao,
    alt: 'Radisson Mai Khao Beach',
    title: 'Radisson Mai Khao Beach',
    description:
      'Total interior for 220 bedroom hotel with all public areas and 3 restaurants.',
    imgGallery: [
      radissonmaikhao1,
      radissonmaikhao2,
      radissonmaikhao3,
      radissonmaikhao4,
      radissonmaikhao5,
      radissonmaikhao6,
    ],
  },
  {
    href: '/portfolio/avani-riverside',
    imgSrc: avaniRiverside,
    alt: 'Avani Riverside',
    title: 'Avani Riverside',
    description: 'All seating for bedrooms and Restaurants and Lobby.',
    imgGallery: [avaniriverside1, avaniriverside2, avaniriverside3],
  },
  {
    href: '/portfolio/amari-watergate',
    imgSrc: amariWatergate,
    alt: 'Amari Watergate',
    title: 'Amari Watergate',
    description: 'All lobby and Library.',
    imgGallery: [amariwatergate1, amariwatergate2, amariwatergate3],
  },
  {
    href: '/portfolio/tal-haus',
    imgSrc: talHaus,
    alt: 'Tal Haus',
    title: 'Tal Haus',
    description: '',
    imgGallery: [
      talhaus1,
      talhaus2,
      talhaus3,
      talhaus4,
      talhaus5,
      talhaus6,
      talhaus7,
      talhaus8,
      talhaus9,
      talhaus10,
      talhaus11,
      talhaus12,
    ],
  },
  {
    href: '/portfolio/the-chind-asia',
    imgSrc: theChindAsia,
    alt: 'Perdomo (The ChindAsia)',
    title: 'Perdomo (The ChindAsia)',
    description: 'All luxury seating and bar Stools.',
    imgGallery: [thechindasia1, thechindasia2, thechindasia3],
  },
  {
    href: '/portfolio/marriott-residence',
    imgSrc: marriotResidence,
    alt: 'Marriott Residence',
    title: 'Marriott Residence',
    description:
      'All Bedrooms sofas and chairs all lobby, restaurants and coffee shop.',
    imgGallery: [marriotresidence1, marriotresidence2, marriotresidence3],
  },
  {
    href: '/portfolio/aml-development',
    imgSrc: amlDevelopment,
    alt: 'Elegance (AML Development)',
    title: 'Elegance (AML Development)',
    description: '58 room luxury Apartments in Pattaya',
    imgGallery: [AML1, AML2, AML3],
  },
  {
    href: '/portfolio/radisson-residence-sukhumvit',
    imgSrc: radissonResidenceSukhumvit,
    alt: 'Radisson Residence Sukhumvit',
    title: 'Radisson Residence Sukhumvit',
    description: 'Indian Restaurant & Full hotel refit',
    imgGallery: [
      radissonresidencesukhumvit1,
      radissonresidencesukhumvit2,
      radissonresidencesukhumvit3,
    ],
  },
  {
    href: '/portfolio/village-lane-wine-bar',
    imgSrc: villageLaneWineBar,
    alt: 'Village Lane Wine Bar',
    title: 'Village Lane Wine Bar',
    description: 'Currently top 10 Trip Advisor.',
    imgGallery: [villagelanewinebar1],
  },
  {
    href: '/portfolio/the-eastern-orient-express-train',
    imgSrc: theEasternOrientExpressTrain,
    alt: 'The Eastern Orient Express Train',
    title: 'The Eastern Orient Express Train',
    description: 'Refit and renovate Seating.',
    imgGallery: [orientexpress1, orientexpress2, orientexpress3],
  },
];

export default function PortfolioIndex() {
  const pathname = useLocation();
  const params = useParams();
  const [imageGallery, setImageGallery] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    portfolioData.find((item) => {
      if (item.href === pathname.pathname) {
        setImageGallery([...item.imgGallery]);
        setTitle(item.title);
      }
    });
    return () => {
      setImageGallery([]);
    };
  }, [pathname, pathname.pathname]);

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      bgOpacity: 0.9,
      gallery: '#images',
      children: 'a',
      pswpModule: PhotoSwipe,
    });
    lightbox.init();
    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, [pathname.pathname]);

  return (
    <main className={styles.main}>
      {Object.keys(params).length === 0 && (
        <Container
          fluid
          className={`text-center my-5 ${styles['portfolio-container']}`}
        >
          <h1>Our portfolio</h1>

          <Row
            className={`${styles['image-row']} mx-xs-1 mx-sm-2 mx-md-3 mx-lg-5`}
          >
            {portfolioData.map((data) => {
              return (
                <Col
                  sm={12}
                  md={4}
                  className="d-flex flex-column"
                  key={data.href}
                >
                  <ImageLinkLayout
                    href={data.href}
                    imgSrc={data.imgSrc}
                    alt={data.alt}
                    title={data.title}
                    description={data.description}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      )}

      {params.portfolioName && (
        <Container className="">
          <h1 className={styles.title}>{title}</h1>
          <Row
            id="images"
            className={`${styles['image-list-container']} d-flex justify-content-center mx-3 mx-md-5`}
          >
            {imageGallery.length > 0 &&
              imageGallery.map((image, idx) => {
                return (
                  <Col
                    sm={6}
                    md={4}
                    lg={3}
                    key={idx}
                    className={`${styles['image-item-container']} my-3`}
                  >
                    <Link
                      to={image}
                      data-pswp-width="1280"
                      data-pswp-height="720"
                      target="_blank"
                    >
                      <img src={image} alt={`${title} ${idx}`} />
                    </Link>
                  </Col>
                );
              })}
          </Row>
        </Container>
      )}
    </main>
  );
}
