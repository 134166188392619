import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import styles from './AboutUs.module.css';

import matthew from '../assets/images/people-images/Matthew-Slater.jpg';
import dilok from '../assets/images/people-images/Dilok-Kaewbao-crop.webp';
import unknownuser from '../assets/images/people-images/unknown-user.jpg';
import klaus from '../assets/images/people-images/KLAUS-KUMMER.webp';

export default function AboutUs() {
  return (
    <main className={styles.main}>
      <Container className={`d-flex my-5 ${styles['about-us-container']}`}>
        <Row className="flex-column w-100">
          <Col>
            <h2>
              Asalon has now been a major manufacture and export company for
              nearly 40 years.
            </h2>
          </Col>
          <Col>
            <p>
              Dealing with everything from a single bespoke chair to whole
              fitout for international Chain Hotels. Our dedicated professional
              team have worked with people all over the world to make all
              projects come true. We have also a lot of experience in suppling
              retail stores from over 60 countries.
            </p>
            <p>
              Our team also loves working together with interior designers,
              architects and buyers. We have so many companies that we work with
              from around the globe. Our import and export manager allows for
              smooth sourcing and on time deliveries.
            </p>
            <p>
              We only source and use the best sustainable raw materials to build
              high end quality furniture, with a frame guarantee of 25 years on
              our sofas, – We know we have the confidence to help condo
              developers and Hoteliers get a strong product that will last.
              Again, for personal customers we can recommend from over 600
              existing inhouse designs that we can amend or make a totally
              bespoke service from Design to Delivery just for you.
            </p>
          </Col>
          <Col>
            <Row>
              <Col sm={12} md={6}>
                <p>
                  <span>Matthew Slater</span> from England is our CEO and has
                  worked in many different professions. He learnt early in his
                  career that service and quality are paramount to long term
                  happy customers that confidentially return month and month and
                  year after year, and sometime bring a friend.
                </p>
                <p>
                  Email:{' '}
                  <Link to="mailto:matt@asalon.co.th">matt@asalon.co.th</Link>
                </p>
              </Col>
              <Col sm={12} md={6} className="text-end text-sm-center">
                <img
                  src={matthew}
                  className="w-70 h-70 "
                  alt="matthew slater"
                ></img>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="d-flex flex-row-reverse">
              <Col>
                <p>
                  <span>Dilok Kaewbao (Att)</span> from Thailand is our
                  Production manager and again was brought up with a service
                  goal orientation. With over 30 years training and experience
                  he is happy to work with customers to make sure nothing is
                  overlooked, and no detail is too small.
                </p>
                <p>
                  Email:{' '}
                  <Link to="mailto:planning@asalon.co.th">
                    planning@asalon.co.th
                  </Link>
                </p>
              </Col>
              <Col className="text-start text-sm-center">
                <img
                  src={dilok}
                  alt="Dilok Kaewbao"
                  className="w-70 h-70 "
                ></img>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <p>
                  <span>Eddy Bellavoine</span> from France is our Director of
                  Marketing. No job too big or too small he takes them all.
                  Anyone who meets Eddy knows instantly that they are in the
                  best and most professional hands.
                </p>
                <p>
                  Email:{' '}
                  <Link to="mailto:Asalonhr@gmail.com">Asalonhr@gmail.com</Link>
                </p>
              </Col>
              <Col className="text-end text-sm-center">
                <img
                  src={unknownuser}
                  alt="Eddy Bellavoine"
                  className="w-70 h-70 "
                ></img>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="d-flex flex-row-reverse">
              <Col>
                <p>
                  <span>Klaus Kummer</span>, Architect and Designer, born in
                  Germany, studied Scandinavian Design in Copenhagen, our
                  business and design consultant. He just makes it happen!
                  Worldwide sourcing experience with extensive Quality Control
                  focus. He is also our Partner in Bison Leather Sofas – know as
                  some of the highest quality sofa available in the world.
                </p>
                <p>
                  Email:{' '}
                  <Link to="mailto:klausk@kdt-inter.com">
                    klausk@kdt-inter.com
                  </Link>
                </p>
              </Col>
              <Col className="text-start text-sm-center">
                <img
                  src={klaus}
                  alt="klaus kummer"
                  className="w-70 h-70 "
                ></img>
              </Col>
            </Row>
          </Col>
          <Col>
            <h3>Asalon Team are here at your service…….</h3>
          </Col>
        </Row>
      </Container>
    </main>
  );
}
