import { Container, Row, Col } from 'react-bootstrap';

import styles from './Footer.module.css';

import logo from '../../assets/logo/asalon-logo.png';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <Container
        fluid
        className={`d-flex ${styles['footer-container']} py-5 w-100 h-100 justify-content-center`}
      >
        <Container className="d-flex justify-content-center mx-0">
          <Row className="w-100 ">
            <Col className="d-flex align-items-center justify-content-center">
              <img src={logo} alt="asalon" className={styles['asalon-logo']} />
            </Col>
            <Col>
              <Row className="flex-column">
                <Col>
                  <Row className="">
                    <ul
                      className={`d-flex justify-content-between ${styles['keyword-list']}`}
                    >
                      <li>INSPIRE</li>
                      <li>INNOVATE</li>
                      <li>FASHION</li>
                      <li>COMFORT</li>
                      <li>STYLE</li>
                    </ul>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col className="d-flex flex-column me-5">
                      <h3>CONTACT</h3>
                      <ul className="d-flex flex-column">
                        <li>Tel: Thai/English +66 8 6669 0070</li>
                        <li>Tel: Admin +66 84-427-6855</li>
                      </ul>
                      <p>
                        120/34 Moo 12, Soi KingKaew 21/2 Rachathewa, Bangplee,
                        Samutprakarn 10540
                      </p>
                      <ul className="d-flex flex-column">
                        <li>
                          <Link to="https://www.theworks.in.th">
                            www.theworks.in.th
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.bisonnaturalauthentic.com">
                            www.bisonnaturalauthentic.com
                          </Link>
                        </li>
                      </ul>
                    </Col>
                    <Col className="">
                      <h3>CONTENT</h3>
                      <ul
                        className={`d-flex flex-column ${styles['navigate-link-list']}`}
                      >
                        <li>
                          <Link to="/">HOME</Link>
                        </li>
                        <li>
                          <Link to="/about-us">ABOUT US</Link>
                        </li>
                        <li>
                          <Link to="/portfolio">PORTFOLIO</Link>
                        </li>
                        <li>
                          <Link to="/contact-us">CONTACT US</Link>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
