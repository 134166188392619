import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import blackCouch from '../assets/images/Snake1.webp';
import lobbyCouch from '../assets/images/lobby-couch.webp';
import avani from '../assets/images/avani.jpg';
import abtwinssands from '../assets/images/ab-twins-sands.webp';
import chic from '../assets/images/chic.webp';
import thechind from '../assets/images/the-chind.jpeg';
import banner from '../assets/banner-images/banner2.jpg';
import marriotresidence from '../assets/images/Marriott-Residence-03.webp';
import radisson from '../assets/images/Radisson-Mai-Kaew-bedroom-home.webp';

import styles from './Home.module.css';

export default function Home() {
  let [clientWidth, setClientWidth] = useState();
  useEffect(() => {
    const windowResize = () => {
      setClientWidth(window.innerWidth);
    };
    window.addEventListener('resize', windowResize);
  });

  return (
    <main className={styles.main}>
      <Container>
        <Container className={styles['introduction-section']}>
          <Row className={clientWidth < 992 && 'flex-column'}>
            <Col sm={12} md={6} className={clientWidth < 992 && 'w-100'}>
              <h2>
                Asalon has now been a major manufacture and export company for
                nearly 40 years.
              </h2>
              <p>
                Dealing with everything from a single bespoke chair to whole
                fitout for international Chain Hotels. Our dedicated
                professional team have worked with people all over the world to
                make all projects come true. We have also a lot of experience in
                suppling retail stores from over 60 countries.
              </p>
              <p>
                Our team also loves working together with interior designers,
                architects and buyers. We have so many companies that we work
                with from around the globe. Our import and export manager allows
                for smooth sourcing and on time deliveries.
              </p>
            </Col>
            <Col sm={12} md={6} className={clientWidth < 992 && 'w-100'}>
              <div className={styles['image-wrapper']}>
                <img
                  src={blackCouch}
                  alt="black-couch"
                  className="w-100 h-100"
                ></img>
              </div>
            </Col>
          </Row>
          <Row className={clientWidth < 992 && 'flex-column-reverse'}>
            <Col sm={12} md={6} className={clientWidth < 992 && 'w-100'}>
              <div className={styles['image-wrapper']}>
                <img
                  src={lobbyCouch}
                  alt="lobby-couch"
                  className="w-100 h-100"
                ></img>
              </div>
            </Col>
            <Col sm={12} md={6} className={clientWidth < 992 && 'w-100'}>
              <h2>
                We only source and use the best sustainable raw materials to
                build high end quality furniture,
              </h2>
              <p>
                with a frame guarantee of 25 years on our sofas, We know we have
                the confidence to help condo developers and Hoteliers get a
                strong product that will last. Again, for personal customers we
                can recommend from over 600 existing inhouse designs that we can
                amend or make a totally bespoke service from Design to Delivery
                just for you.
              </p>
            </Col>
          </Row>
        </Container>
        <Container
          className={`${styles.quotes} d-flex flex-column align-items-center`}
        >
          <span>&ldquo;</span>
          <p>
            Asalon Is a Total Solution Factory and fitting Factory here in
            Bangkok and we would love the chance to talk and work together. We
            have worked being chief Buying agents as well for FF&E and OS&E
          </p>
          <p>
            We have really Enjoyed doing the following projects in the last
            couple of years.
          </p>
          <span>&rdquo;</span>
        </Container>
        <Container className={`${styles.portfolio} d-flex flex-column`}>
          <h1 className="align-self-center">
            OUR <span>PORTFOLIO</span>
          </h1>
          <Container className="px-0">
            <Row>
              <Col sm={12} md={6} className="d-flex flex-column">
                <div className={styles['image-wrapper']}>
                  <Link to="/portfolio/avani-riverside">
                    <img src={avani} alt="avani-hotel" className=""></img>
                  </Link>
                </div>
                <Link to="/portfolio/avani-riverside">
                  <h3>Avani Riverside</h3>
                </Link>

                <p>All seating for bedrooms, restaurants and lobby</p>
              </Col>
              <Col md={6} className="d-flex flex-column">
                <div className={styles['image-wrapper']}>
                  <Link to="/portfolio/ab-twin-sands">
                    <img
                      src={abtwinssands}
                      alt="ab twin sands"
                      className=""
                    ></img>
                  </Link>
                </div>
                <Link to="/portfolio/ab-twin-sands">
                  <h3>Ab Twin Sands</h3>
                </Link>

                <p></p>
              </Col>
              <Col md={6} className="d-flex flex-column">
                <div className={styles['image-wrapper']}>
                  <Link to="/portfolio/chic">
                    <img src={chic} alt="chic" className=""></img>
                  </Link>
                </div>
                <Link to="/portfolio/chic">
                  <h3>Chic</h3>
                </Link>

                <p></p>
              </Col>
              <Col md={6} className="d-flex flex-column">
                <div className={styles['image-wrapper']}>
                  <Link to="/portfolio/the-chind-asia">
                    <img
                      src={thechind}
                      alt="Perdomo (The Chind Asia)"
                      className=""
                    ></img>
                  </Link>
                </div>
                <Link to="/portfolio/the-chind-asia">
                  <h3>Perdomo (The Chind Asia)</h3>
                </Link>

                <p>All luxury seating and bar Stool</p>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
      <Container fluid className="px-0 py-5">
        <img
          src={banner}
          alt="hotel room bed furnitures"
          className="w-100 h-100"
        ></img>
      </Container>
      <Container className={`${styles.portfolio} d-flex flex-column`}>
        <Container className="d-flex justify-content-center">
          <Row className="justify-content-between">
            <Col md={6} className="d-flex flex-column">
              <div className={styles['image-wrapper']}>
                <Link to="/portfolio/marriott-residence">
                  <img
                    src={marriotresidence}
                    alt="marriot residence"
                    className=""
                  ></img>
                </Link>
              </div>
              <Link to="/portfolio/marriott-residence">
                <h3>Marriott Residence</h3>
              </Link>
              <p>
                All Bedrooms sofas and chairs all lobby, restaurants and coffee
                shop.
              </p>
            </Col>
            <Col md={6} className="d-flex flex-column">
              <div className={styles['image-wrapper']}>
                <Link to="/portfolio/radisson-mai-khao-beach">
                  <img
                    src={radisson}
                    alt="raddison mai khao beach"
                    className=""
                  ></img>
                </Link>
              </div>
              <Link to="/portfolio/radisson-mai-khao-beach">
                <h3>Radisson Mai Khao Beach</h3>
              </Link>
              <p>All luxury seating and bar Stool</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </main>
  );
}
