import { Container, Row, Col } from 'react-bootstrap';

import styles from './ContactUs.module.css';

export default function ContactUs() {
  return (
    <main className={styles.main}>
      <Container
        className={`${styles['contact-container']} d-flex flex-column align-items-sm-start align-items-md-center`}
      >
        <h1 className={styles.title}>CONTACT US</h1>
        <ul>
          <li>Tel: Thai/English +66 8 6669 0070</li>
          <li>Tel: Admin +66 84-427-6855</li>
        </ul>

        <p>
          120/34 Moo 12, Soi KingKaew 21/2 Rachathewa, Bangplee, Samutprakarn
          10540
        </p>
      </Container>
    </main>
  );
}
