import Navigation from './Navigation';
import styles from './Header.module.css';
import { useLocation } from 'react-router-dom';

export default function Header() {
  const { pathname } = useLocation();

  let containerBanner = styles['container-banner-home'];

  if (pathname.includes('/about-us')) {
    containerBanner = styles['container-banner-about-us'];
  } else if (pathname.includes('/portfolio')) {
    containerBanner = styles['container-banner-portfolio'];
  } else if (pathname.includes('/contact-us')) {
    containerBanner = styles['container-banner-contact-us'];
  }

  return (
    <div className={`${styles.container} ${containerBanner}`}>
      <Navigation />
    </div>
  );
}
