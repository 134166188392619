import { Link } from 'react-router-dom';

import { Container } from 'react-bootstrap';

import styles from './image-link-layout.module.css';

export default function ImageLinkLayout(props) {
  return (
    <>
      <Link to={props.href} className={styles['image-link']}>
        <Container className={styles.imgContainer}>
          <img src={props.imgSrc} alt={props.alt} className=""></img>
        </Container>
      </Link>
      <Link to={props.href} className={styles.title}>
        {props.title}
      </Link>
      <p className={styles.description}>{props.description}</p>
    </>
  );
}
